import React, { Component } from 'react';
import Plot from 'react-plotly.js';
import PropTypes from 'prop-types';

let layout = {
    barmode: 'group',
    autosize: true,
    width: 800
}

export default class BarGraph extends Component{
    render(){
        return (
            <Plot
                data={this.props.traces}
                layout={layout}
            />
        )
    }
}

BarGraph.propTypes = {
    traces: PropTypes.array,
}