import React, { Component } from 'react';
import { getCore, postCore } from '../util/ajax';
import { Form, Header, Segment, Button, Input } from 'semantic-ui-react';

export default class Delete extends Component{
    state = {
        fields: {},
        confirmName: "",
        error: false,
        buttonOff: true
    }

    async componentDidMount(){
        let getFields = await getCore(`/admin/edit/${this.props.resource}/${this.props.resourceId}`);

        if( getFields.data.adminResource && getFields.data.adminResource.resource ){
            let resource = JSON.parse(getFields.data.adminResource.resource);

            this.setState({
                fields: resource
            })
        }
    }

    handleClick = async () => {
        let confirmString = this.props.confirmField && this.state.fields[this.props.confirmField] ? this.state.fields[this.props.confirmField] : "DELETE";
        if (confirmString === this.state.confirmName ){
            let deleteAction = await postCore(`/admin/delete/${this.props.resource}/${this.props.resourceId}`);
            if( deleteAction.data.adminResourceDelete.success ){
                return this.props.onSuccess();
            }
        }
    }

    handleChange = e => {
        let confirmString = this.props.confirmField && this.state.fields[this.props.confirmField] ? this.state.fields[this.props.confirmField] : "DELETE";
        this.setState({
            confirmName: e.target.value,
            error: !confirmString.includes(e.target.value),
            buttonOff: confirmString !== e.target.value
        })
    }

    render(){
        return (
            <Segment>
                <Header as="h3">Really Delete?</Header>
                <Form.Group widths='equal' fluid style={{ marginTop: '1em', marginBottom: '1em' }}>
                    <Input 
                        name='confirmName' 
                        fluid 
                        placeholder={`Type "${this.props.confirmField && this.state.fields[this.props.confirmField] ? this.state.fields[this.props.confirmField] : "DELETE"}" to delete...`} 
                        onChange={this.handleChange}
                        error={this.state.error}
                    />
                </Form.Group>
                <Button 
                    color='red' 
                    fluid 
                    onClick={!this.state.buttonOff ? this.handleClick : () => {}}
                    disabled={this.state.buttonOff} 
                >
                    Confirm Delete
                </Button>
            </Segment>
        )
    }
}