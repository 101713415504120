import React from 'react';
import TableIO from '../../../ui/TableIO';
import DateLookup from '../../../ui/DateLookup';
import moment from 'moment';
import qs from 'qs';
import { getCore } from '../../../util/ajax';
import { Segment } from 'semantic-ui-react';

export const UsersByDateReport = ({level, history}) => {
    return (
        <TableIO 
            title="Search By Date"

            fields={[
                {
                    name: "startDate",
                    label: "Start Date",
                    render: props => <DateLookup {...props} />,
                    default: moment(Date.now()).subtract(7, 'days').toDate()
                },
                {
                    name: "endDate",
                    label: "End Date",
                    render: props => <DateLookup {...props} />,
                    default: moment(Date.now()).toDate()
                }
            ]}

            coerceInput={raw => {
                return {
                    startDate: moment(raw.startDate).format("YYYY-MM-DD"),
                    endDate: moment(raw.endDate).format("YYYY-MM-DD"),
                }
            }}

            fetchData={async input => {
                let { data } = await getCore(`/admin/users/all/?${qs.stringify(input)}`)
                console.log(data)
                return data.adminUsers;
            }}

            onRowClick={(e, data) => {
                console.log(data)
                history.push(`/users/user/${data.rowData.id}`);
            }}

            showColumns={[
                "id",
                "email",
                "name",
                "first_name",
                "last_name",
                "created"
            ]}   

            modifyRow={row => {
                row.created = moment(row.created).format("DD-MM-YYYY");

                return row;
            }}

            runImmediate={true}

            default={() => (
                <Segment><strong>No results for selected time period.</strong></Segment>
            )}            
        />          
    )
}