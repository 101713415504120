import React from 'react';
import { Input } from 'semantic-ui-react';
import uuid from 'uuid';

import ViewUser from '../users/user/ViewUser';
import EditUser from '../users/user/EditUser';
import DeleteUser from '../users/user/DeleteUser';
import FilteredResource from '../ui/FilteredResource';
import { Resource } from '../ui/Resource';
import NewResource from '../ui/NewResource';
import ResourceLookup from '../ui/ResourceLookup';
import StrongPassword from '../ui/StrongPassword';

export const users = [
    {
        text: "Recent",
        link: "/users/",
        component: (props) => <Resource resource="company_users" redirectPath={"/users/user/"} {...props} />,
        exact: true,
    },
    {
        text: "New",
        link: "/users/new/",
        component: props => (
            <NewResource 
                {...props}
                resource="company_users" 
                redirectPath={"/users/user/"} 
                title="New User"
                fieldOverrides={{
                    "id": () => <Input disabled />,
                    "x_id": () => <Input disabled value={uuid.v1()} />,
                    "email": props => <Input {...props} type='email' />,
                    "password": props => <StrongPassword type='password' name="password" {...props} />,
                    "company_id" : (val, onChange) => <ResourceLookup resource="company" name="company_id" onChange={onChange}/>,
                    "phone": props => <Input {...props} type="number" />,
                    "zip": props => <Input {...props} type="number" />,
                }}

                groupFields={{
                    "Basic Information": [
                        "id",
                        "first_name",
                        "last_name",
                        "phone",
                        "email",
                    ],

                    "Address Information": [
                        "address",
                        "city",
                        "state",
                        "zip",
                    ],

                    "Company Information": [
                        "company_id",
                    ],

                    "Password Information": [
                        "password",
                    ]
                }}
            />
        )
    }
]

export const user = (userId, companyId) => [
    {
        text: "View",
        link: () => `/users/user/${userId}`,
        component: (props) => <ViewUser {...props} resourceId={userId} />,
        exact: true,
    },
    {
        text: "Company",
        link: () => `/users/user/${userId}/company`,
        component: (props) => (
            <FilteredResource 
                {...props} 
                resourceId={companyId} 
                resource="company"
                showColumns={[
                    "id",
                    "name"
                ]}
                redirectTarget="/companies/company/" 
                column="id" 
            />
        ),
        exact: true
    },     
    {
        text: "Edit",
        link: () => `/users/user/${userId}/edit`,
        component: (props) => <EditUser {...props} resourceId={userId} />,
        exact: true,
    },
    {
        text: "Delete",
        link: () => `/users/user/${userId}/delete`,
        component: (props) => <DeleteUser  {...props} resourceId={userId} />,
        exact: true,
    },    
]
