import React, { Component } from 'react';
import { getCore, postCore } from '../util/ajax';
import { Form, Segment, Header, Divider, Grid, GridColumn, Button } from 'semantic-ui-react';
import uuid from 'uuid';
import UploadCSV from './UploadCSV';
import DownloadCSV from './DownloadCSV';

export default class NewResource extends Component{
    state = {
        fields: {}
    }

    async componentDidMount(){
        this.fetchData();
    }

    fetchData = async () => {
        let getFields = await getCore(`/admin/new/${this.props.resource}/`);

        if (getFields.data.adminResourceNew && getFields.data.adminResourceNew.resource) {
            let resource = JSON.parse(getFields.data.adminResourceNew.resource);

            if( resource.hasOwnProperty("x_id") ) resource.x_id = uuid.v1();

            this.setState({
                fields: resource
            })
        }
    }

    onChange = ({target}) => {
        let fields = {...this.state.fields};
        fields[target.name] = target.value;

        this.setState({
            fields
        })
    }

    handleSave = async () => {
        if( this.props.onSave ){
            return this.props.onSave(this.state)
        }

        if( this.props.cleanInput ){
            this.state.fields = this.props.cleanInput(this.state.fields)
        }

        let errorFields = [];
        if (this.props.requiredFields && this.props.requiredFields.length > 0){
            this.props.requiredFields.forEach(required => {
                if (!this.state.fields[required] || this.state.fields[required] === "")
                    errorFields.push(required)
            })
        }
        if (errorFields.length > 0){
            if (this.props.handleError)
                return this.props.handleError(errorFields)
            return null
        }

        try{
            let save = await postCore(`/admin/new/${this.props.resource}/`, {
                payload: JSON.stringify(this.state.fields)
            });

            if( save.data.adminResourceCreate.success ){
                this.props.history.push(this.props.redirectPath)
            }
        } catch(e) {
            if (this.props.handleError)
                return this.props.handleError(e)
            return null
        }
    }

    groupRecords = (carry, next) => {
        if( this.props.hideKeys && this.props.hideKeys.includes(next) ){
            return carry;
        }

        let recordGroupLength = this.props.grouping || 2;
        let counter = 0;

        while(carry[counter]){
            if( carry[counter].length === recordGroupLength ){
                counter++;
            }
            else if( carry[counter].length === 1 ){
                carry[counter].push(next);

                return carry;
            }
        }

        if( carry[counter] === undefined ){
            carry[counter] = [];
            carry[counter].push(next)
            return carry;
        }

        return carry;
    }

    renderFieldset = (fields) => {
        return fields.map(key => {
            const fieldVal = this.state.fields[key];
            const formatted = key.replace(/_/g, " ");
            const hasOverride = this.props.fieldOverrides && this.props.fieldOverrides[key];

            if( hasOverride ){
                let props = {
                    value: fieldVal,
                    name: key,
                    onChange: this.onChange,
                }

                return (
                    <Form.Field key={key}>
                        <label style={{textTransform: 'capitalize'}}>{formatted}</label>
                        { this.props.fieldOverrides[key](props) }
                    </Form.Field>                                                
                )
            }
            else{
                return (
                    <Form.Field key={key}>
                        <label style={{textTransform: 'capitalize'}}>{formatted}</label>
                        <input name={key} value={fieldVal} onChange={this.onChange}/>
                    </Form.Field>
                )
            }
        })
    }

    renderFormGroup = (fields) => {
        if( this.props.groupFields && Object.entries(fields).length !== 0 ){
            let targetFields = this.props.groupFields;

            return Object.keys(targetFields).map(cat => {
                let arr = targetFields[cat];
                let groupedFields = arr.reduce(this.groupRecords, []);
                return (
                    <Segment key={cat}>
                        <Header as="h4">{cat}</Header>
                        <Divider style={{marginTop: '1em'}}/>
                        { groupedFields.map(fields => {
                            return (
                                <Form.Group key={fields} widths='equal'> 
                                    { this.renderFieldset(fields) }
                                </Form.Group>   
                            )
                        })}
                    </Segment>
                )
            })
        }

        return Object.keys(fields).reduce(this.groupRecords, []).map(fieldset => {
            return (
                <Form.Group widths='equal'> 
                    {this.renderFieldset(fieldset)}
                </Form.Group>                
            )
        })
    }

    updateFields = (updatedFields) => this.setState({ fields: updatedFields })

    grabFields = () => {
        let targetFields = this.props.groupFields instanceof Function ? this.props.groupFields(this.state.fields) : this.props.groupFields;
        let keys = Object.values(targetFields).flat();
        let entries = Object.entries(this.state.fields).flat();
        let keyValues = [];
        let headerRow = [];
        let valuesRow = [];

        entries.forEach((item, index) => {
            if (keys.indexOf(item) > -1) {
                keyValues.push(item)
                keyValues.push(entries[index + 1])
            }
        })
        keyValues.forEach(item => {
            if (keyValues.indexOf(item) % 2 === 0) {
                headerRow.push(item)
            }
            else {
                valuesRow.push(item)
            }
        })
        return `${encodeURI([headerRow, valuesRow].join("\n"))}`;
    }

    render(){
        return (
            <Form autoComplete="off">
                <Grid>
                    <GridColumn width={8}>
                        <Header as="h3">{this.props.title}</Header>    
                    </GridColumn>
                    <GridColumn width={8}>
                        <Grid>
                            <DownloadCSV grabFields={this.grabFields} newResource={true} {...this.props}/>   
                            <UploadCSV updateFields={this.updateFields} {...this.props} />
                        </Grid>
                    </GridColumn>
                </Grid>
                
                <Divider style={{ marginTop: '1em', marginBottom: '1em' }} />
                { this.renderFormGroup(this.state.fields) }  
                <Button primary onClick={this.handleSave}>{this.props.saveText || "Save"}</Button>
            </Form>
        )
    }
}