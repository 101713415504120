import React from 'react';
import moment from 'moment';
import qs from 'qs';
import { Header, Divider, Segment } from 'semantic-ui-react';
import TableIO from '../../ui/TableIO';
import { getCore } from '../../util/ajax';
import { startEndDateMixin } from '../../ui/GraphIO';

export default () => {
        return (
            <div>
                <Header as="h2">All Scores</Header>
                <Divider style={{marginTop: '1em', marginBottom: '2em'}} />                
                <TableIO 
                    title="Search By Date"

                    {...startEndDateMixin()}

                    fetchData={async input => {
                        let { data } = await getCore(`/admin/scores/all/?${qs.stringify(input)}`)

                        return data.adminScores;
                    }}

                    showColumns={[
                        "id",
                        "name",
                        "score_date",
                        "level",
                        "tevo",
                        "contact_email",
                    ]}   

                    modifyRow={row => {
                        row.score_date = moment(row.score_date).format("DD-MM-YYYY");

                        return row;
                    }}         
                    
                    default={() => (
                        <Segment><strong>No results for selected time period.</strong></Segment>
                    )}
                    scores={true}
                />
            </div>
        )
}