import React from 'react';
import { Input, TextArea, Button } from 'semantic-ui-react';
import NewPartners from './items/NewPartners';
import EditPartner from './partner/EditPartner';
import DeletePartner from './partner/DeletePartner';
import ViewPartner from './partner/ViewPartner';
import RelationshipsPartner from './partner/RelationshipsPartner';
import FilteredResource from '../ui/FilteredResource';
import { Resource } from '../ui/Resource';
import NewResource from '../ui/NewResource';
import ResourceLookup from '../ui/ResourceLookup';
import QuickNew from '../ui/QuickNew';
import { dropdown } from '../ui/Inputs';
import { abbreviated } from '../data/states';
import StrongPassword from '../ui/StrongPassword';
import ProAffiliates from './items/ProAffiliates';
import { postCore } from '../util/ajax';

const deleteRow = async (resource, resourceId) => {
    let response = await postCore(`/admin/delete/${resource}/${resourceId}`);

    if ( response.data.adminResourceDelete.success )
        window.location.reload();
}


export const partners = [
    {
        text: "Recent",
        link: "/partners/",
        component: (props) => <NewPartners {...props} />,
        exact: true,
    },
    {
        text: "New",
        link: "/partners/new/",
        component: props => (
            <NewResource 
                {...props}
                resource="affiliates" 
                redirectPath={"/partners/"} 
                title="New Partner"
                fieldOverrides={{
                    "id": () => <Input disabled />,
                    "description": props => <TextArea {...props} />,
                    "contact_email": props => <Input {...props} type='email' />,
                    "state": ({name, value, onChange}) => dropdown(name, value, onChange, Object.keys(abbreviated).map(key => ({
                        text: key,
                        value: key
                    }))),
                    "affiliate_specialty": props => <ResourceLookup resource="partner_specialty" name="affiliate_specialty" searchColumn="title" onChange={props.onChange} />,
                    "proaffiliate_id": ({ onChange }) => <ProAffiliates onChange={onChange} />,
                    "password": props => <StrongPassword type='password' name="password" {...props} />,
                    "phone": props => <Input {...props} type="number" />,
                    "contact_phone": props => <Input {...props} type="number" />,
                    "zip": props => <Input {...props} type="number" />,
                    "affiliate_type": ({ name, value, onChange }) => dropdown(name, value, onChange, [
                        { text: 'Affiliate', value: 'Affiliate' },
                        { text: 'ProAffiliate', value: 'ProAffiliate' }
                    ])
                }}

                groupFields={{
                    "Basic Information": [
                        "name",
                        "phone",
                        "description",
                    ],

                    "Contact Information": [
                        "contact_first_name",
                        "contact_last_name",
                        "contact_phone",
                        "contact_email",
                    ],

                    "Address Information": [
                        "address",
                        "address_2",
                        "city",
                        "state",
                        "zip"
                    ],

                    "Plan Information": [
                        "affiliate_type",
                        "affiliate_specialty",
                        "proaffiliate_id"
                    ],

                    "Other Information": [
                        "web",
                    ],

                    "Password Information": [
                        "password",
                    ]
                }}
            />
        )
    },    
    {
        text: "Services",
        link: "/partners/services/",
        component: props => (
            <>
                <Resource 
                    resource="partner_service" 
                    // redirectPath={"/scores/score/"} 
                    autoDispatch={true} 
                    showColumns={[
                        "id",
                        "title",
                        "delete"
                    ]}
                    modifiedHeaders={() => ({
                        delete: {
                            text: "Delete",
                            transform: (v, i, row) => <Button size="small" color="orange" onClick={() => deleteRow("partner_service", row.id)}>Delete</Button>
                        }
                    })}
                    {...props} 
                />         
                <QuickNew 
                    {...props}
                    resource="partner_service" 
                    redirectPath={"/partners/services/"} 
                    title="New Service"
                    fieldOverrides={{}}                  
                />
            </>   
        )
    },
    {
        text: "Categories",
        link: "/partners/categories/",
        component: props => (
            <>
                <Resource 
                    resource="partner_category"
                    autoDispatch={true}
                    showColumns={[
                        "id",
                        "title",
                        "delete"
                    ]}
                    modifiedHeaders={() => ({
                        delete: {
                            text: "Delete",
                            transform: (v, i, row) => <Button size="small" color="orange" onClick={() => deleteRow("partner_category", row.id)}>Delete</Button>
                        }
                    })}
                    {...props}
                />
                <QuickNew 
                    {...props}
                    resource="partner_category" 
                    redirectPath={"/partners/categories/"} 
                    title="New Category"
                    fieldOverrides={{}}                  
                />   
            </>         
        )
    },
    {
        text: "Specialties",
        link: "/partners/specialties/",
        component: props => (
            <>
                <Resource 
                    resource="partner_specialty"
                    autoDispatch={true}
                    showColumns={[
                        "id",
                        "title",
                        "delete"
                    ]}
                    modifiedHeaders={() => ({
                        delete: {
                            text: "Delete",
                            transform: (v, i, row) => <Button size="small" color="orange" onClick={() => deleteRow("partner_specialty", row.id)}>Delete</Button>
                        }
                    })}
                    {...props}
                />
                <QuickNew 
                    {...props}
                    resource="partner_specialty" 
                    redirectPath={"/partners/specialties/"} 
                    title="New Specialty"
                    fieldOverrides={{}}                  
                />    
            </>           
        )
    }    
]

export const partner = partnerId => [
    {
        text: "View",
        link: () => `/partners/partner/${partnerId}`,
        component: (props) => <ViewPartner {...props} resourceId={partnerId} />,
        exact: true,
    },
    {
        text: "Relationships",
        link: () => `/partners/partner/${partnerId}/relationships`,
        component: (props) => <RelationshipsPartner {...props} resourceId={partnerId} />,
        exact: true
    },
    {
        text: "Services",
        link: () => `/partners/partner/${partnerId}/services`,
        component: (props) => (
            <FilteredResource resourceId={partnerId} resource="partner_to_service" column="partner_id" showColumns={["id", "service_id", "partner_id"]}/>
        ),
        exact: true
    },    
    {
        text: "Users",
        link: () => `/partners/partner/${partnerId}/users`,
        component: (props) => <FilteredResource {...props} resourceId={partnerId} resource="affiliate_users" redirectTarget="/partner-users/user/" column="affiliate_id" />,
        exact: true
    },
    {
        text: "Edit",
        link: () => `/partners/partner/${partnerId}/edit`,
        component: (props) => <EditPartner {...props} resourceId={partnerId} />,
        exact: true,
    },
    {
        text: "Delete",
        link: () => `/partners/partner/${partnerId}/delete`,
        component: (props) => <DeletePartner  {...props} resourceId={partnerId} />,
        exact: true,
    },    
]