import React from 'react';
import moment from 'moment';

import NewResource from '../../ui/NewResource';
import ResourceLookup from '../../ui/ResourceLookup';
import Selector from '../../ui/Selector';
import { Input } from 'semantic-ui-react';
import DateLookup from '../../ui/DateLookup';

const overrides = {
  "company_id" : props => (
    <ResourceLookup 
      resource="company"
      {...props}
    />
  ),
  "tevo": props => <Input {...props} type="number"/>,
  "assets": props => <Input {...props} type="number"/>,
  "revenue": props => <Input {...props} type="number"/>,
  "naics": props => <Input {...props} type="number"/>,

  "employees": props => <Input {...props} type="number"/>,
  "net_income": props => <Input {...props} type="number"/>,
  "ar": props => <Input {...props} type="number"/>,
  "cash": props => <Input {...props} type="number"/>,
  "ebitda": props => <Input {...props} type="number"/>,
  "multiple_ebitda_upper": props => <Input {...props} type="number"/>,
  "multiple_ebitda_estimate": props => <Input {...props} type="number"/>,
  "multiple_ebitda_lower": props => <Input {...props} type="number"/>,
  "multiple_revenue_upper": props => <Input {...props} type="number"/>,
  "multiple_revenue_estimate": props => <Input {...props} type="number"/>,
  "multiple_revenue_lower": props => <Input {...props} type="number"/>,
  "multiple_assets_upper": props => <Input {...props} type="number"/>,
  "multiple_assets_estimate": props => <Input {...props} type="number"/>,
  "multiple_assets_lower": props => <Input {...props} type="number"/>,
  "multiple_net_income_upper": props => <Input {...props} type="number"/>,
  "multiple_net_income_estimate": props => <Input {...props} type="number"/>,
  "multiple_net_income_lower": props => <Input {...props} type="number"/>,

  "score_date": props => <DateLookup {...props}/>
}

const levelOneFields = {
  "Basic Information": [
    "company_id",
    "tevo"
  ],
  "Financial Input": [
    "revenue",
    "assets",
    "naics",
    "score_date"
  ]
}

const levelTwoFields = {
  "Basic Information": [
    "company_id",
    "tevo",
    "score_date",
  ],
  "Financial Input": [
      "revenue",
      "assets",
      "employees",
      "net_income",
      "ar",
      "cash",
      "ebitda"
  ],
  "EBITDA Multiples": [
    "multiple_ebitda_upper",
    "multiple_ebitda_estimate",
    "multiple_ebitda_lower"
  ],
  "Revenue Multiples": [
    "multiple_revenue_upper",
    "multiple_revenue_estimate",
    "multiple_revenue_lower"
  ],
  "Assets Multiples" : [
    "multiple_assets_upper",
    "multiple_assets_estimate",
    "multiple_assets_lower"
  ],
  "Net Income Multiples": [
    "multiple_net_income_upper",
    "multiple_net_income_estimate",
    "multiple_net_income_lower"
  ]
}

const parseFieldDates = (fields) => {
  if (fields.score_date){
    fields.score_date = moment(fields.score_date).format('YYYY-MM-DD HH:mm:ss')
    fields.created_at = moment(fields.score_date).format('YYYY-MM-DD HH:mm:ss')
    fields.updated_at = moment(fields.score_date).format('YYYY-MM-DD HH:mm:ss')
  } else {
    fields.score_date = moment().format('YYYY-MM-DD HH:mm:ss')
    fields.created_at = moment().format('YYYY-MM-DD HH:mm:ss')
    fields.updated_at = moment().format('YYYY-MM-DD HH:mm:ss')
  }
  return fields
}

export default props => {
  return ([
    <h1>Manually Insert Scores</h1>,
    <Selector options={[
      {
        name: "Provisional",
        render: (
          <NewResource 
            {...props}
            resource="scores" 
            redirectPath={"/scores/"} 
            title="New Provisional"
            fieldOverrides={{ ...overrides }}
            requiredFields={["company_id", "tevo", "revenue", "naics"]}

            cleanInput={(fields) => {
              fields = parseFieldDates(fields);
              fields.manually_created = true
              fields.level = 1
              return fields
            }}

            groupFields={{...levelOneFields}}
          />                        
        )
      },
      {
        name: "Multiples Indicator",
        render: (
          <NewResource 
            {...props}
            resource="scores" 
            redirectPath={"/scores/score/"} 
            title="New Multiples Indicator"
            fieldOverrides={{ ...overrides }}
            requiredFields={
              [
                "company_id",
                "tevo",
                "revenue",
                "assets",
                "employees",
                "net_income",
                "ar",
                "cash",
                "ebitda",
                "multiple_ebitda_upper",
                "multiple_ebitda_estimate",
                "multiple_ebitda_lower",
                "multiple_revenue_upper",
                "multiple_revenue_estimate",
                "multiple_revenue_lower",
                "multiple_assets_upper",
                "multiple_assets_estimate",
                "multiple_assets_lower",
                "multiple_net_income_upper",
                "multiple_net_income_estimate",
                "multiple_net_income_lower"
              ]
            }

            cleanInput={(fields) => {
              fields = parseFieldDates(fields);
              fields.manually_created = true
              fields.level = 2
              return fields
            }}

            groupFields={{ ...levelTwoFields }}
          />                        
        )
      }
    ]}/>
  ])
}