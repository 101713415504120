import React from 'react';
import Edit from '../../ui/Edit';
import { dropdown, toggle } from '../../ui/Inputs';
import { abbreviated } from '../../data/states';
import { defaultFields, levelOneFields, levelTwoFields } from './shared/Fields';

export default (props) => {
    return (
        <Edit 
            title="Edit Score" 
            resource="scores" 

            fieldOverrides={{
                "state": ({ name, value, onChange }) => dropdown(name, value, onChange, Object.keys(abbreviated).map(key => ({
                    text: key,
                    value: key
                }))),

                "deleted" : (key, val, onChange) => toggle(key, val, onChange),
                "active" : (key, val, onChange) => toggle(key, val, onChange),
                "review" : (key, val, onChange) => toggle(key, val, onChange)
            }}

            groupFields={fields => {
                    switch(parseInt(fields.level)){
                        case 1:
                            return {
                                ...defaultFields,
                                ...levelOneFields
                            }
                        case 2: 
                            return {
                                ...defaultFields,
                                ...levelTwoFields
                            }
                        default:
                            return {
                                ...defaultFields
                            }
                        
                    }
                }}
            {...props} 
        />
    )
}