import React, { Component } from 'react';
import { Header, Divider } from 'semantic-ui-react';
import valueIndexMap from '../../data/valueIndexMap.json';
import { Resource } from '../../ui/Resource'

export default class ValueIndex extends Component{
    render(){
        return (
            <div>
                <Header as="h2">Value Index Reports</Header>
                <Divider style={{marginTop: '1em', marginBottom: '2em'}} />
                <Resource 
                    {...this.props} 
                    resource="l3Scores" 
                    redirectPath="/scores/score/" 
                    title="Value Indexes"
                    showColumns={[
                        "id",
                        "pending",
                        "version",
                        "annualRevenue",
                        "b2bb2c",
                        "executiveTeamSize",
                        "netMargin",
                    ]}
                    modifyRow={(row) => {
                        console.log(row)
                        row.pending = row.pending === 0 ? "Completed" : "Pending";
                        row.netMargin = valueIndexMap.netMargin[row.netMargin];
                        row.annualRevenue = valueIndexMap.annualRevenue[row.annualRevenue];
                        row.executiveTeamSize = valueIndexMap.executiveTeamSize[row.executiveTeamSize] ? valueIndexMap.executiveTeamSize[row.executiveTeamSize] : row.executiveTeamSize
                        return row;
                    }}
                    modifiedHeaders = {() => ({
                        pending: {text: "Pending"},
                        version: {text: "Version"},
                        annualRevenue: {text: "Annual Revenue"},
                        b2bb2c: {text: "B2B/B2C"},
                        executiveTeamSize: {text: "Team"},
                        netMargin: {text: "Net Margin"}
                    })}
                />
            </div>
        )
    }
}
