import React from 'react';
import { Checkbox, TextArea, Input } from 'semantic-ui-react';
import { moment } from 'moment';
import Edit from '../../ui/Edit';
import { dropdown } from '../../ui/Inputs';
import { abbreviated } from '../../data/states';

export default (props) => {
    return (
        <Edit
            redirectPath={`/companies/company/${props.resourceId}`}
            title="Edit" 
            resource="company" 

            fieldOverrides={{
                "state": ({name, value, onChange}) => dropdown(name, value, onChange, Object.keys(abbreviated).map(key => ({
                    key: key,
                    text: key,
                    value: key
                }))),
                "structure" : ({name, value, onChange}) => dropdown(name, value, onChange, [
                    { text: 'LLC', value: 'llc' },
                    { text: 'SCORP', value: 'scorp' },
                    { text: 'PC', value: 'pc'},
                    { text: 'LP', value: 'lp' }
                ]),
                "contact_email" : props => <Input {...props} type="email" />,
                "description": props => <TextArea {...props}/>,
                "is_private": props => <Checkbox {...props}/>
            }}

            groupFields={{
                "Basic Information": [
                    "name",
                    "description"
                ],

                "Contact Information": [
                    "contact_first_name",
                    "contact_last_name",
                    "contact_title",
                    "contact_phone",
                    "contact_email",
                    "phone"
                ],

                "Address Information": [
                    "address_1",
                    "address_2",
                    "city",
                    "state",
                    "zip"
                ],

                "Financial Information": [
                    "naics",
                    "revenue",
                    "ebitda",
                    "is_private"
                ],

                "Extended Information": [
                    "website",
                    "ubi",
                    "linkedin",
                    "structure"
                ],
            }}

            cleanInput={input => {
                input.created_at = moment(input.created_at).format('YYYY-MM-DD');
                input.updated_at = moment(input.updated_at).format('YYYY-MM-DD');
                input.deleted_at = moment(input.updated_at).format('YYYY-MM-DD');

                return input;
            }}

            {...props} 
        />
    )
}