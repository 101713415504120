import React, { Component } from 'react';
import { getCore } from '../util/ajax';
import { Form, Header, Divider, Segment } from 'semantic-ui-react';

export default class View extends Component{
    state = {
        fields: {}
    }

    async componentDidMount(){
        let getFields = await getCore(`/admin/edit/${this.props.resource}/${this.props.resourceId}`);

        if( getFields.data.adminResource && getFields.data.adminResource.resource ){
            let resource = JSON.parse(getFields.data.adminResource.resource);

            this.setState({
                fields: resource
            })
        }
    }

    groupRecords = (carry, next) => {
        if( this.props.hideKeys && this.props.hideKeys.includes(next) ){
            return carry;
        }

        let recordGroupLength = this.props.grouping || 2;
        let counter = 0;

        while(carry[counter]){
            if( carry[counter].length === recordGroupLength ){
                counter++;
            }
            else if( carry[counter].length === 1 ){
                carry[counter].push(next);

                return carry;
            }
        }

        if( carry[counter] === undefined ){
            carry[counter] = [];
            carry[counter].push(next)
            return carry;
        }

        return carry;
    }

    renderFieldset = (fields) => {
        return fields.map(key => {
            const fieldVal = this.state.fields[key];
            const formatted = key.replace(/_/g, " ");
            const hasOverride = this.props.fieldOverrides && this.props.fieldOverrides[key];

            if( hasOverride ){
                console.log(fieldVal, typeof this.props.fieldOverrides[key])
                return (
                    <p key={key}>
                        <strong style={{textTransform: 'capitalize'}}>{formatted}: </strong>
                        { this.props.fieldOverrides[key](fieldVal) }
                    </p>                                                
                )
            }
            else{
                return (
                    <p key={key}>
                        <strong style={{textTransform: 'capitalize'}}>{formatted}: </strong>
                        {fieldVal}
                    </p>
                )
            }
        })
    }

    renderFormGroup = (fields) => {
        if( this.props.groupFields && Object.entries(fields).length !== 0 ){
            let targetFields = this.props.groupFields instanceof Function ? this.props.groupFields(this.state.fields) : this.props.groupFields;

            return Object.keys(targetFields).map(cat => {
                let arr = targetFields[cat];
                let groupedFields = arr.reduce(this.groupRecords, []);
                return (
                    <Segment key={cat}>
                        <Header as="h4">{cat}</Header>
                        <Divider style={{marginTop: '1em'}}/>
                        { groupedFields.map(fields => {
                            return (
                                this.renderFieldset(fields)
                            )
                        })}
                    </Segment>
                )
            })
        }

        return Object.keys(fields).reduce(this.groupRecords, []).map(fieldset => {
            return (
                <Form.Group widths='equal'> 
                    {this.renderFieldset(fieldset)}
                </Form.Group>                
            )
        })
    }

    render(){
        return (
            <Form autoComplete="off">
                { this.renderFormGroup(this.state.fields) }  
            </Form>
        )
    }
}