import React, { Component } from 'react';
import moment from 'moment';
import uuidv1 from 'uuid/v1';
import NewResource from '../../../ui/NewResource';
import { postCore, getCore } from '../../../util/ajax';
import { revertCamelCase } from '../../../util/camel';
import { Button } from 'semantic-ui-react';

const multiplesFields = [
  {
    key: "multiple_ebitda_upper",
    title: "EBITDA Upper",
  },
  {
    key: "multiple_ebitda_estimate",
    title: "EBITDA Estimate",
  },
  {
    key: "multiple_ebitda_lower",
    title: "EBITDA Lower",
  },
  {
    key: "multiple_revenue_upper",
    title: "Revenue Upper",
  },
  {
    key: "multiple_revenue_estimate",
    title: "Revenue Estimate",
  },
  {
    key: "multiple_revenue_lower",
    title: "Revenue Lower",
  },
  {
    key: "multiple_assets_upper",
    title: "Assets Upper",
  },
  {
    key: "multiple_assets_estimate",
    title: "Assets Estimate",
  },
  {
    key: "multiple_assets_lower",
    title: "Assets Lower",
  },
  {
    key: "multiple_net_income_upper",
    title: "Net Income Upper",
  },
  {
    key: "multiple_net_income_estimate",
    title: "Net Income Estimate",
  },
  {
    key: "multiple_net_income_lower",
    title: "Net Income Lower",
  },
  {
    key: "tevo_upper",
    title: "Tevo Upper",
  },
  {
    key: "tevo_lower",
    title: "Tevo Lower",
  },
  {
    key: "tevo",
    title: "Tevo",
  }
]

export default class Provisional extends Component {

  state = {
    isDisplay: false,
    isError: false,
    errorMsg: ""
  }

  handleSubmit = async ({ fields }) => {
    let requiredFields= [
      "company_id",
      "naics",
      "revenue",
      "assets",
      "ebitda",
      "cash",
      "ar",
      "inventory",
      "total_liabilities",
      "employees"
    ]

    let isError = false;
    requiredFields.forEach( required => {
      if(!fields[required] || fields[required] === "")
        isError = true
    })
    if (isError){
      return this.setState({
        isDisplay: false,
        isError: true,
        errorMsg: "All fields are required"
      })
    }

    let rev_emp = parseInt(fields.employees) > 0 ? parseFloat( (parseInt(fields.revenue) / parseInt(fields.employees)).toFixed(2) ) : 0;
    let params = {
      naics: parseInt(fields.naics),
      revenue: fields.revenue,
      assets: fields.assets,
      ebitda: fields.ebitda,
      cash: fields.cash,
      ar: fields.ar,
      inventory: fields.inventory,
      total_liabilities: fields.total_liabilities,
      employees: fields.employees,
      rev_emp: rev_emp,
    }

    let { data } = await postCore('/scores/multiples/create/', params);

    let res = await getCore(`/admin/edit/company/${fields.company_id}`);
    let resource = JSON.parse(res.data.adminResource.resource)

    let companyUpdateData = {
      level_two_assets: parseInt(fields.assets),
      level_two_revenue: parseInt(fields.revenue),
      level_two_ebitda: parseInt(fields.ebitda),
      level_two_inventory: parseInt(fields.inventory),
      level_two_cash: parseInt(fields.cash),
      level_two_employees: parseInt(fields.employees),
      level_two_liabilities: parseInt(fields.total_liabilities),
      level_two_ar: parseInt(fields.ar)
    };

    let scoreData = {

      level: 2,
      score_date: moment().format('YYYY-MM-DD HH:mm:ss'),
      created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
      x_id: uuidv1(),
      company_id: fields.company_id,
      naics: parseInt(fields.naics),
      
      tevo: data.getMultiples.multiples.extended,
      base_tevo: data.getMultiples.multiples.tevo,
      
      tevo_upper: data.getMultiples.industry_metrics.tevo.max,
      tevo_lower: data.getMultiples.industry_metrics.tevo.min,
      
      tevo_min: data.getMultiples.industry_metrics.tevo.min,
      tevo_median: data.getMultiples.industry_metrics.tevo.median,
      tevo_max: data.getMultiples.industry_metrics.tevo.max,
      
      naics_sector: data.getMultiples.naics_sector_code,
      
      revenue: fields.revenue,
      assets: fields.assets,
      ar: fields.ar,
      inventory: fields.inventory,
      total_liabilities: fields.total_liabilities,
      number_of_employee: data.getMultiples.employee_count,
      employees: data.getMultiples.employee_count,
      ebitda: fields.ebitda,
      cash: fields.cash,
      
      multiple_revenue_lower: data.getMultiples.multiples.multiple_revenue_lower,
      multiple_revenue_estimate: data.getMultiples.multiples.multiple_revenue_median,
      multiple_revenue_upper: data.getMultiples.multiples.multiple_revenue_upper,
      multiple_ebitda_lower: data.getMultiples.multiples.multiple_ebitda_lower,
      multiple_ebitda_estimate: data.getMultiples.multiples.multiple_ebitda_median,
      multiple_ebitda_upper: data.getMultiples.multiples.multiple_ebitda_upper,
      multiple_net_income_lower: data.getMultiples.multiples.multiple_net_income_lower,
      multiple_net_income_estimate: data.getMultiples.multiples.multiple_net_income_median,
      multiple_net_income_upper: data.getMultiples.multiples.multiple_net_income_upper,
      multiple_assets_lower: data.getMultiples.multiples.multiple_assets_lower,
      multiple_assets_estimate: data.getMultiples.multiples.multiple_assets_median,
      multiple_assets_upper: data.getMultiples.multiples.multiple_assets_upper,
      
      percentile_revenue: data.getMultiples.percentRanks.revenue,
      percentile_ebitda: data.getMultiples.percentRanks.ebitda,
      
      percentile_cash: data.getMultiples.percentRanks.cash,
      percentile_ar: data.getMultiples.percentRanks.ar,
      percentile_inventory: data.getMultiples.percentRanks.inventory,
      percentile_assets: data.getMultiples.percentRanks.assets,
      percentile_total_liabilities: data.getMultiples.percentRanks.total_liabilities,
      percentile_rev_emp: data.getMultiples.percentRanks.rev_emp,
      
      waterfall_revenue: data.getMultiples.waterfall.revenue,
      waterfall_ebitda: data.getMultiples.waterfall.ebitda,
      waterfall_cash: data.getMultiples.waterfall.cash,
      waterfall_ar: data.getMultiples.waterfall.ar,
      waterfall_inventory: data.getMultiples.waterfall.inventory,
      waterfall_total_liabilities: data.getMultiples.waterfall.total_liabilities,
      waterfall_rev_emp: data.getMultiples.waterfall.rev_emp,
      waterfall_assets: data.getMultiples.waterfall.assets,
    }

    this.setState({
      scoreData,
      companyUpdateData,
      companyId: fields.company_id,
      companyName: resource.name,
      isDisplay: true
    })
  }

  handleSave = async () => {
    try{
      let scoreData = { ...this.state.scoreData };
      let companyUpdate = { ...this.state.companyUpdateData }

      let scoresSave = await postCore(`/admin/new/scores/`, {
        payload: JSON.stringify(scoreData)
      });

      let companySave = await postCore(`/admin/save/company/${this.state.companyId}`, {
        payload: JSON.stringify(companyUpdate)
      })

      if( scoresSave.data.adminResourceCreate.success && companySave.data.adminResourceSave.success ){
        this.props.history.push('/scores/')
      }
    } catch(e) {
      this.handleReset();
      this.setState({ isError: true, errorMsg: e.message })
    }
  }

  handleReset = () => {
    this.setState({
      isDisplay: false,
      isError: false,
      errorMsg: ""
    })
  }

  renderDisplay = (key) => {
    let str = revertCamelCase(key);
    return <p><strong>{str}</strong>: {this.state.score[key]}</p>
  }

  render(){
    if (!this.state.isDisplay)
      return ([
        <NewResource 
          {...this.props}
          resource="scores" 
          title="New Multiples Indicator"
          fieldOverrides={{ ...this.props.overrides }}
          onSave={this.handleSubmit}
          saveText="Build"
          groupFields={{ ...this.props.fields }}
        />,
        this.state.isError ? <p>{this.state.errorMsg}</p> : null
      ])
    return (
      <div>
        <h1>Confirm Multiples Indicator for {this.state.companyName}</h1>
        {multiplesFields.map( field => (
          <p><strong>{field.title}</strong>: {this.state.scoreData[field.key]}</p>
        ))}
        <Button.Group>
          <Button positive onClick={this.handleSave}>Save</Button>
          <Button.Or />
          <Button onClick={this.handleReset}>Start Over</Button>
        </Button.Group>
      </div>
    )
  }
}