import React from 'react';
import TableIO from '../../../ui/TableIO';
import DateLookup from '../../../ui/DateLookup';
import moment from 'moment';
import qs from 'qs';
import { getCore } from '../../../util/ajax';
import { Segment } from 'semantic-ui-react';

export const ScoresByDateReport = ({level, history}) => {
    return (
        <TableIO 
            title="Search By Date" 

            fields={[
                {
                    name: "startDate",
                    label: "Start Date",
                    render: props => <DateLookup {...props} />,
                    default: moment(Date.now()).subtract(7, 'days').toDate()
                },
                {
                    name: "endDate",
                    label: "End Date",
                    render: props => <DateLookup {...props} />,
                    default: moment(Date.now()).toDate()
                }
            ]}

            coerceInput={raw => {
                return {
                    startDate: moment(raw.startDate).format("YYYY-MM-DD"),
                    endDate: moment(raw.endDate).format("YYYY-MM-DD"),
                    level,
                }
            }}

            fetchData={async input => {
                let { data } = await getCore(`/admin/scores/all/?${qs.stringify(input)}`)

                return data.adminScores;
            }}

            onRowClick={((e, record) => {
                history.push(`/companies/company/${record.rowData.id}`);
            })}

            showColumns={[
                "id",
                "name",
                "score_date",
                "tevo",
                "contact_email",
            ]}   

            modifyRow={row => {
                row.score_date = moment(row.score_date).format("DD-MM-YYYY");

                return row;
            }}

            default={() => (
                <Segment><strong>No results for selected time period.</strong></Segment>
            )}
        />          
    )
}