import React from 'react';
import { Input } from 'semantic-ui-react';
import { Resource } from '../ui/Resource';
import ViewUser from './user/ViewUser';
import EditUser from './user/EditUser';
import DeleteUser from './user/DeleteUser';
import FilteredResource from '../ui/FilteredResource';
import NewResource from '../ui/NewResource';
import ResourceLookup from '../ui/ResourceLookup';
import StrongPassword from '../ui/StrongPassword';
import { dropdown } from '../ui/Inputs';

export const users = [
    {
        text: "Recent",
        link: "/partner-users/",
        component: (props) => <Resource resource="affiliate_users" redirectPath={"/partner-users/user/"} {...props} />,
        exact: true,
    },
    {
        text: "New",
        link: "/partner-users/new/",
        component: props => (
            <NewResource 
                {...props}
                resource="affiliate_users" 
                redirectPath={"/partner-users/"} 
                title="New Partner User"
                fieldOverrides={{
                    "id": () => <Input disabled />,
                    "email": props => <Input {...props} type='email' />,
                    "password" : props => <StrongPassword type='password' name="password" {...props}/>,
                    "subscribed_plan_id": ({ name, value, onChange }) => dropdown(name, value, onChange, [
                        { text: 'Trial', value: 'trial' },
                        { text: 'Reseller', value: 'reseller' },
                        { text: 'Gold', value: 'gold' },
                        { text: 'Silver', value: 'silver' },
                        { text: 'Platinum', value: 'platinum' }
                    ]),
                    "affiliate_id" : ({onChange}) => <ResourceLookup resource="affiliates" name="affiliate_id" onChange={onChange}/>,
                    "work_phone": props => <Input {...props} type="number"/>,
                    "mobile": props => <Input {...props} type="number" />
                }}

                groupFields={{
                    "Basic Information": [
                        "id",
                        "first_name",
                        "last_name",
                        "work_phone",
                        "mobile",
                        "email",
                    ],

                    "Extended Information": [
                        "subscribed_plan_id",
                        "affiliate_id",
                    ],

                    "Password Information": [
                        "password",
                    ]
                }}
            />
        )
    }
]

export const user = (userId, affiliateId) => [
    {
        text: "View",
        link: () => `/partner-users/user/${userId}`,
        component: (props) => <ViewUser {...props} resourceId={userId} />,
        exact: true,
    },
    {
        text: "Partner",
        link: () => `/partner-users/user/${userId}/company`,
        component: (props) => (
            <FilteredResource 
                {...props} 
                resourceId={affiliateId} 
                resource="affiliates" 
                showColumns={[
                    "id",
                    "name"
                ]}
                redirectTarget="/partners/partner/" 
                column="id" 
            />
        ),
        exact: true
    },      
    {
        text: "Edit",
        link: () => `/partner-users/user/${userId}/edit`,
        component: (props) => <EditUser {...props} resourceId={userId} />,
        exact: true,
    },
    {
        text: "Delete",
        link: () => `/partner-users/user/${userId}/delete`,
        component: (props) => <DeleteUser  {...props} resourceId={userId} />,
        exact: true,
    },    
]
