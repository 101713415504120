import React from 'react';
import TableIO from './TableIO';
import DateLookup from './DateLookup';
import moment from 'moment';
import qs from 'qs';
import { getCore } from '../util/ajax';

export const Resource = ({level, history, resource, redirectPath, showColumns, modifyRow, modifiedHeaders}) => {
    return (
        <TableIO 
            title="Search By Date"

            fields={[
                {
                    name: "startDate",
                    label: "Start Date",
                    render: props => <DateLookup {...props} />,
                    default: moment(Date.now()).subtract(7, 'days').toDate()
                },
                {
                    name: "endDate",
                    label: "End Date",
                    render: props => <DateLookup {...props} />,
                    default: moment(Date.now()).toDate()
                }
            ]}

            coerceInput={raw => raw}

            fetchData={async input => {
                let { data } = await getCore(`/admin/list/${resource}/?${qs.stringify(input)}`)

                return JSON.parse(data.adminResourceList.resource);
            }}

            onRowClick={(e, data) => {
                history.push(`${redirectPath}${data.rowData.id}`);
            }}

            showColumns={showColumns || [
                "id",
                "email",
                "name",
                "first_name",
                "last_name",
                "created"
            ]}

            modifiedHeaders={modifiedHeaders}

            modifyRow={row => {
                row.created = moment(row.created).format("MMMM DD, YYYY");
                row.active_until = moment(row.active_until).format("MMMM DD, YYYY");

                if (modifyRow)
                    return modifyRow(row)

                return row;
            }}

            runImmediate={true}
        />         
    )
}