import React, { Component } from 'react';
import { Form, Button, Header, Divider, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class InputHandler extends Component{
    state = {
        data: [],
        hide: false,
        filter: "",
    }

    componentDidMount(){
        let defaultState = this.props.fields.reduce((c, n) => ({ ...c, ...{ [n.name] : n.default } }), {})

        this.setState({
            ...defaultState
        })

        if( this.props.runImmediate ){
            this.handleSubmit();
        }
    }

    handleChange = (e, data) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    handleSubmit = async () => {
        let stateCopy = { ...this.state };
        delete stateCopy.data;
        let inputData = this.props.coerceInput(stateCopy);
        let data = await this.props.fetchData(inputData)

        if( data ){
            data = data.map((row, idx) => this.props.modifyRow(row, stateCopy, idx) );

            data = data.map(row => {
                let tmp = {};
                this.props.exposeColumns.forEach(key => {
                    tmp[key] = row[key];
                })
                return tmp;
            })
        }

        this.setState({
            data: data,
            filter: "",
            hide: false
        })
    }    

    runRenderer = () => {
        if( !this.state.hide ){
            return this.props.renderer(this.state, this.props)
        }
        else{
            return null;
        }
    }

    toggleDisplay = () => {
        this.setState({
            hide: !this.state.hide
        })
    }

    exportData = () => {
        let values = [];
        let keys = [];
        const keyValues = this.state.data.map(item => ({
            id: item.id,
            name: item.name,
            score: item.tevo,
            level: item.level,
            contact_email: item.contact_email,
            score_date: item.score_date
        }))
        keyValues.forEach(object => {
            keys.push(Object.keys(object))
            values.push(Object.values(object))
        });
        let headerRow = keys[0];
        let encodedUri = `${encodeURI([headerRow, values.join('\n')].join('\n'))}`
        let link = document.createElement("a");
        link.setAttribute("href", `data:text/csv;charset=utf-8,${encodedUri}`);
        link.setAttribute("target", "_blank");
        link.setAttribute("download", `infovera_scores.csv`)
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render(){
        return (
            <div>
                <Segment>
                    <Form>
                        <Header as="h3">{this.props.title}
                        {this.props.scores ? <Button color='yellow' floated='right' onClick={this.exportData} className="export-scores"> Export Scores </Button> : null }
                        </Header>
                        <Divider />
                        <Form.Group widths='equal'>  
                            { this.props.fields.map(field => (
                                <Form.Field key={field.name}>
                                    <label>{field.label}</label>
                                    { field.render({
                                        onChange: this.handleChange,
                                        ...field
                                    }) }
                                </Form.Field>
                            )) } 
                            { !this.props.hideFilter && (
                                <Form.Field key="filter">
                                    <label>Filter</label>
                                    <input onChange={this.handleChange} name="filter" />
                                </Form.Field> 
                            ) }                        
                        </Form.Group>

                        <Button primary onClick={this.handleSubmit}>Search</Button>    

                        {this.state.hide ? 
                            <Button color='green' floated="right" onClick={this.toggleDisplay}>Show</Button> :
                            <Button color='red' floated="right" onClick={this.toggleDisplay}>Hide</Button>        
                        }
                    </Form>
                    { this.runRenderer() }   
                </Segment>
            </div>       
        )       
    }
}

InputHandler.propTypes = {
    fields: PropTypes.array.isRequired,
    title: PropTypes.string,
    coerceInput: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    filterRow: PropTypes.object,
    exposeColumns: PropTypes.array,
    hideFilter: PropTypes.bool
}