import React from 'react';
import { Divider, Header } from 'semantic-ui-react';
import qs from 'qs';
import { getCore } from '../../util/ajax';
import TableIO from '../../ui/TableIO';

export default ({history}) =>  {
    return (
        <div>
            <Header as="h2">Companies</Header>
            <Divider style={{marginTop: '1em', marginBottom: '2em'}} />
            <TableIO 
                   title="Recent"
                   fields={[
                       {
                           name: "search",
                           label: "Search",
                           render: props => <input type='text' {...props} />,
                           default: '',
                       }
                   ]}

                    fetchData={async input => {
                        let { data } = await getCore(`/admin/companies/all/?${qs.stringify(input)}`)
                        return data.adminCompanies;
                    }}

                    showColumns={[
                        "id",
                        "name",
                        "city",
                        "state",
                        "naics",
                        "deleted",
                    ]}   

                    coerceInput={raw => raw}

                    modifyRow={row => row}   

                    onRowClick={(e, record) => {
                        history.push(`/companies/company/${record.rowData.id}`);
                    }}

                    runImmediate={true}
                    hideFilter={true}
            />
        </div>
    )
}
