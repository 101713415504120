import React from 'react';
import View from '../../ui/View';

export default (props) => {
    return (
        <View 
            resource="affiliate_users" 

            fieldOverrides={{
                "active": value => value === 0 ? "False" : "True",
                "deleted": value => value === 0 ? "False" : "True"
            }}

            groupFields={{
                "Basic Information": [
                    "id",
                    "first_name",
                    "last_name",
                    "work_phone",
                    "mobile",
                    "email",
                ],

                "Extended Information": [
                    "subscribed_plan_id",
                    "affiliate_id",
                ],

                "Other": [
                    "active",
                    "deleted"
                ]
            }}
            {...props} 
        />
    )
}