import React from 'react';
import { Divider, Header } from 'semantic-ui-react';
import qs from 'qs';
import { getCore } from '../../util/ajax';
import TableIO from '../../ui/TableIO';
import moment from 'moment';

export default ({history}) =>  {
    return (
        <div className='partner-recent'>
            <Header as="h2">Partners</Header>
            <Divider style={{marginTop: '1em', marginBottom: '2em'}} />
            <TableIO 
                   title="Recent"
                   fields={[
                       {
                           name: "search",
                           label: "Search",
                           render: props => <input type='text' {...props} />,
                           default: '',
                       }
                   ]}

                    fetchData={async input => {
                        let checkedColumns = document.querySelectorAll(".partner-recent input[type=checkbox]");
                        let names = Array.from(checkedColumns).filter(el => el.checked).map(el => el.value)

                        let query = {
                            search: input.search,
                            columns: JSON.stringify(names),
                        };

                        let { data } = await getCore(`/admin/list/affiliates/?${qs.stringify(query)}`)
                        
                        return JSON.parse(data.adminResourceList.resource);
                    }}

                    showColumns={[
                        "id",
                        "name",
                        "address",
                        "city",
                        "state",
                        "zip",
                        "created_at"
                    ]}   

                    coerceInput={raw => {
                        return raw;
                    }}

                    modifyRow={row => {
                        row.created_at = moment(row.created_at).format('MM/DD/YYYY');

                        return row;
                    }}   

                    onRowClick={(e, record) => {
                        history.push(`/partners/partner/${record.rowData.id}`);
                    }}

                    runImmediate={true}
                    hideFilter={true}
            />
        </div>
    )
}
