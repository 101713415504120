import React, { Component } from 'react';
import { Pagination, Segment } from 'semantic-ui-react';
import SmartTable from 'react-smart-data-table';
import PropTypes from 'prop-types';
import InputHandler from './InputHandler';

export default class TableIO extends Component{
    render(){
        return (
            <InputHandler 
                fields={this.props.fields}
                title={this.props.title}
                coerceInput={this.props.coerceInput}
                fetchData={this.props.fetchData}
                modifyRow={this.props.modifyRow}
                exposeColumns={this.props.showColumns}
                runImmediate={this.props.runImmediate}
                hideFilter={this.props.hideFilter}
                scores={this.props.scores}
                renderer={(state, props) => {
                    return state.data && state.data.length ? (
                        <SmartTable 
                            data={state.data} 
                            className='ui compact selectable table inverted celled striped'
                            headers={this.props.modifiedHeaders ? this.props.modifiedHeaders() : {}}
                            parseBool={true}
                            withLinks={true}
                            withToggles={true}
                            paginator={Pagination}
                            perPage={10}
                            onRowClick={this.props.onRowClick}
                            filterValue={state.filter}
                            sortable
                        />                        
                    ) : this.props.default ? this.props.default() : <Segment><strong>No results.</strong></Segment>
                }}
            />    
        )       
    }
}

TableIO.propTypes = {
    fields: PropTypes.array.isRequired,
    title: PropTypes.string,
    coerceInput: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    filterRow: PropTypes.object,
    showColumns: PropTypes.array
}