import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import InputHandler from './InputHandler';
import BarGraph from './BarGraph';
import DateLookup from './DateLookup';

export const startEndDateMixin = (fetchData) => ({
    coerceInput: raw => ({
        startDate: moment(raw.startDate).format("YYYY-MM-DD"),
        endDate: moment(raw.endDate).format("YYYY-MM-DD"),
    }),

    fields: [{
        name: "startDate",
        label: "Start Date",
        render: props => <DateLookup {
            ...props
        }
        />,
        default: moment(Date.now()).subtract(7, 'days').toDate()
    },
    {
        name: "endDate",
        label: "End Date",
        render: props => <DateLookup {
            ...props
        }
        />,
        default: moment(Date.now()).toDate()
    }],
})

export default class GraphIO extends Component{
    render(){
        return (
            <InputHandler 
                fields={this.props.fields}
                title={this.props.title}
                coerceInput={this.props.coerceInput}
                fetchData={this.props.fetchData}
                modifyRow={this.props.modifyRow}
                exposeColumns={this.props.showColumns}
                renderer={(state, props) => {
                    return (
                        <BarGraph traces={state.data} />               
                    )
                }}
                hideFilter={true}
            />    
        )       
    }
}

GraphIO.propTypes = {
    fields: PropTypes.array.isRequired,
    title: PropTypes.string,
    coerceInput: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    filterRow: PropTypes.object,
    showColumns: PropTypes.array
}