import React, { Component } from 'react';
import './app.sass';
import { Auth } from './authentication/Auth';
import Main from './Main';
import { postCore } from './util/ajax';
import { BrowserRouter as Router } from 'react-router-dom';

class App extends Component {
  firebase = window.firebase;

  state = {
    authenticated: false,
    scriptsRendered: false,
    error: ""
  }

  componentDidMount(){
    this.runBootstrapScripts();
    this.registerFirebaseListener();
  }

  registerFirebaseListener = async () => {
      this.firebase.auth().onAuthStateChanged(async (user) => {
        if( user && user.email ){
          if( user.email.includes("@infovera.com") ){
          
            this.firebase.auth().currentUser.getIdToken(true).then(async idToken => {
              localStorage.setItem("access_token", idToken);
              localStorage.setItem("email", user.email);

              await postCore(`/admin/authenticate`, {
                token: idToken,
              });

              this.setState({
                authenticated: true
              })
            })
          }
        }
        else{
          this.setState({
            error: "This domain is unauthorized."
          })
        }
      })    
  }

  loginAction = () => {
    let provider = new this.firebase.auth.GoogleAuthProvider();

    this.firebase.auth().signInWithPopup(provider).then(function (result) {}).catch(function (error) {
      console.log(error)
    });
  }

  renderApplication(){
    if( this.state.authenticated ){
      return <Main />
    }
    else{
      return <Auth loginAction={this.loginAction} />
    }
  }

  runBootstrapScripts(){
    if( process.env.REACT_APP_ENVIRONMENT === "development" && !this.state.scriptsRendered ){
      this.setState({
        scriptsRendered: true
      })

      var config = {
        apiKey: "AIzaSyBaU_gmZfWcMWMN-zkJLbdU3CYgEqTtB2s",
        authDomain: "admin-staging-bb008.firebaseapp.com",
        databaseURL: "https://admin-staging-bb008.firebaseio.com",
        projectId: "admin-staging-bb008",
        storageBucket: "admin-staging-bb008.appspot.com",
        messagingSenderId: "65593283322"
      };
      
      this.firebase.initializeApp(config);
    }
    else{

    }
  }

  render() {
    return (
      <div className="App">
          <Router>
            { this.renderApplication() }
          </Router>
      </div>
    );
  }
}

export default App;
