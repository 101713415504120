import React  from 'react';
import Delete from '../../ui/Delete';

export default (props) => {
    return (
        <Delete 
            resource="company_users"
            onSuccess={ () =>  props.history.push("/users/") }
            {...props}
        />
    )
}