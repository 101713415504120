import React from 'react';
import moment from 'moment';
import { TextArea, Input } from 'semantic-ui-react';
import Edit from '../../ui/Edit';
import { dropdown } from '../../ui/Inputs';
import { abbreviated } from '../../data/states';
import ResourceLookup from '../../ui/ResourceLookup';
import ProAffiliates from '../items/ProAffiliates';

export default (props) => {
    return (
        <Edit 
            title="Edit" 
            resource="affiliates" 
            redirectPath={`/partners/partner/${props.resourceId}`} 

            fieldOverrides={{
                "state": ({name, value, onChange}) => dropdown(name, value, onChange, Object.keys(abbreviated).map(key => ({
                    text: key,
                    value: key
                }))),
                "description": props => <TextArea {...props} />,
                "zip": props => <Input {...props} type="number" />,
                "phone": props => <Input {...props} type="number" />,
                "contact_phone": props => <Input {...props} type="number" />,
                "contact_email": props => <Input {...props} type="email" />,
                "affiliate_type": ({ name, value, onChange }) => dropdown(name, value, onChange, [
                    { text: 'Affiliate', value: 'Affiliate' },
                    { text: 'ProAffiliate', value: 'ProAffiliate' }
                ]),
                "affiliate_specialty": ({ onChange }) => <ResourceLookup resource="partner_specialty" name="affiliate_specialty" searchColumn="title" onChange={onChange} valueKey="title" />,
                "proaffiliate_id": ({ onChange }) => <ProAffiliates onChange={onChange} />,
            }}

            cleanInput ={input => {
                input.created_at = moment(input.created_at).format('YYYY-MM-DD');
                input.updated_at = moment(input.updated_at).format('YYYY-MM-DD');
                input.deleted_at = moment(input.updated_at).format('YYYY-MM-DD');

                return input;
            }}

            groupFields={{
                "Basic Information": [
                    "name",
                    "phone",
                    "description",
                ],

                "Contact Information": [
                    "contact_first_name",
                    "contact_last_name",
                    "contact_phone",
                    "contact_email",
                ],

                "Address Information": [
                    "address",
                    "address_2",
                    "city",
                    "state",
                    "zip"
                ],

                "Plan Information": [
                    "affiliate_type",
                    "affiliate_specialty",
                    "proaffiliate_id"
                ],

                "Other Information": [
                    "web"
                ]
            }}
            {...props} 
        />
    )
}