import React from 'react';
import { Divider, Header } from 'semantic-ui-react';
import { getCore } from '../../util/ajax';
import moment from 'moment';
import qs from 'qs';
import GraphIO, { startEndDateMixin } from '../../ui/GraphIO';


export default () => {
    return (
        <div>
            <Header as="h2">Registration &amp; Completion Trend</Header>
            <Divider style={{marginTop: '1em', marginBottom: '2em'}} />                
            <GraphIO 
                title="Search By Date"

                {...startEndDateMixin()}

                showColumns={[
                    "x",
                    "y",
                    "type",
                    "name",
                ]}   

                fetchData = {async input => {
                    input.level = 2;
                    let multiplesRes = await getCore(`/admin/scores/all/?${qs.stringify(input)}`);
                    input.level = 3;
                    let valueIndexRes = await getCore(`/admin/scores/all/?${qs.stringify(input)}`);
                    let users = await getCore(`/admin/users/all/?${qs.stringify(input)}`);

                    return [multiplesRes.data.adminScores, valueIndexRes.data.adminScores, users.data.adminUsers];
                }}

                modifyRow={(row, state, idx) => {
                    let now = moment(state.endDate).add(7, "days");
                    let labels = [];
                    let values = [];

                    while(now.isAfter(state.startDate)){
                        now = now.subtract(7, "days");
                        labels.push(now.format("MM/DD/YY"));
                    }

                    labels.forEach((week, idx, arr) => {
                        values.push(
                            row.filter(r => {
                                if( r.score_date ){
                                    return moment(r.score_date).isBetween(arr[idx + 1], week, '(]')
                                }

                                return moment(r.created).isBetween(arr[idx + 1], week, '(]')
                            }).length
                        )
                    })

                    let label = '';

                    switch(idx){
                        case 0:
                            label = "Multiples Indicators";
                        break;
                        case 1:
                            label="Value Indexes";
                        break;
                        case 2:
                            label="User Registrations";
                        break;

                        default:
                            label = ""
                    }

                    labels.reverse();
                    values.reverse();

                    return { x: labels, y: values, type: 'bar', name: label };
                }}
            />   
        </div>        
    )
}