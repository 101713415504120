import React, { Component } from 'react';
import NewResource from '../../../ui/NewResource';
import ResourceLookup from '../../../ui/ResourceLookup';
import { 
  Input,
  Dropdown,
  Form,
  Segment,
  Header,
  Divider,
  Grid,
  GridColumn,
  Button
} from 'semantic-ui-react';
import { getCore } from '../../../util/ajax';

const fieldOverrides = {
  "companyId" : props => (
    <ResourceLookup 
      resource="company"
      {...props}
    />
  ),
  "naics": props => <Input {...props} type="number"/>,
  "version" : props => (
    <Dropdown
      fluid
      selection
      {...props}
      onChange={(e, {name, value}) => props.onChange({ target: {name, value} })}
      options={[
        {
          key: "1.0.1",
          id: "1.0.1",
          value: "1.0.1",
          text: "1.0.1"
        }
      ]}
    />
  ),
  "businessType" : props => (
    <Dropdown
      fluid
      selection
      {...props}
      onChange={(e, {name, value}) => props.onChange({ target: {name, value} })}
      options={[
        {
          key: 'llc',
          id: 'llc',
          value: 'llc',
          text: 'LLC'
        },
        {
          key: 'scorp',
          id: 'scorp',
          value: 'scorp',
          text: 'S-CORP'
        },
        {
          key: 'pc',
          id: 'pc',
          value: 'pc',
          text: 'PC'
        },
        {
          key: 'lp',
          id: 'lp',
          value: 'lp',
          text: 'LP'
        }
      ]}
    />
  ),
  "annualRevenue": props => (
    <Dropdown
      fluid
      selection
      {...props}
      onChange={(e, {name, value}) => props.onChange({ target: {name, value} })}
      options={[
        {
          key: 'LT5M',
          id: 'LT5M',
          value: 'LT5M',
          text: '< $5M'
        },
        {
          key: 'to25M',
          id: 'to25M',
          value: 'to25M',
          text: '$5M - $25M'
        },
        {
          key: 'GT25M',
          id: 'GT25M',
          value: 'GT25M',
          text: '> $25M'
        }
      ]}
    />
  ),
  "b2bb2c": props => (
    <Dropdown
      fluid
      selection
      {...props}
      onChange={(e, {name, value}) => props.onChange({ target: {name, value} })}
      options={[
        {
          key: 'B2B',
          id: 'B2B',
          value: 'B2B',
          text: 'B2B'
        },
        {
          key: 'B2C',
          id: 'B2C',
          value: 'B2C',
          text: 'B2C'
        }
      ]}
    />
  ),
  "executiveTeamSize": props => (
    <Dropdown
      fluid
      selection
      {...props}
      onChange={(e, {name, value}) => props.onChange({ target: {name, value} })}
      options={[
        {
          key: '1',
          id: '1',
          value: '1',
          text: '1'
        },
        {
          key: '2',
          id: '2',
          value: '2',
          text: '2'
        },
        {
          key: '3to4',
          id: '3to4',
          value: '3to4',
          text: '3 - 4'
        },
        {
          key: 'GT4',
          id: 'GT4',
          value: 'GT4',
          text: '> 4'
        }
      ]}
    />
  ),
  "netMargin": props => (
    <Dropdown
      fluid
      selection
      {...props}
      onChange={(e, {name, value}) => props.onChange({ target: {name, value} })}
      options={[
        {
          key: 'LT20',
          id: 'LT20',
          value: 'LT20',
          text: '< 20%'
        },
        {
          key: 'GT20',
          id: 'GT20',
          value: 'GT20',
          text: '> 20%'
        }
      ]}
    />
  )
}

export default class ValueIndex extends Component {

  state = {
    activeSection: 1,
    isError: false,
    errorMsg: "",
    data: {},
    answers: {},
    sections: []
  }

  getQuestions = async () => {
    let { data } = await getCore(`/questions/categories/${this.state.data.version}`)
    let categories = data.getValueIndexCategories.categories;
    let sections = [];
    let questionCount = 0;

    for (let category of categories){
      let res = await getCore(`/questions/section/${category}/${this.state.data.version}`)
      sections.push({...res.data.getSection, category});
      questionCount += res.data.getSection.questions.length
    }

    this.setState({
      categories,
      sections,
      questionCount,
      activeSection: 2
    })
  }

  handleChange = (value) => {
    let data = {
      answer: value.value,
      section: value.section
    };
    let answers = {...this.state.answers}
    answers[value.name] = data;
    this.setState({ answers })
  }

  handleSubmit = ({fields}) => {
    let requiredFields = [
      "companyId",
      "naics",
      "businessType",
      "annualRevenue",
      "b2bb2c",
      "executiveTeamSize",
      "netMargin",
      "version"
    ]
    let isError = false;
    requiredFields.forEach( required => {
      if (fields[required] === "")
        isError = true
    })
    if (isError)
      return null
    else
      this.setState({ data: {...fields}, isSectionTwo: true }, () => this.getQuestions())
  }

  handleSave = () => {
    let answers = {...this.state.answers};
    let postAnswers = [];
    for( let key in answers){
      let postAnswer = {
        qnum: parseInt(key.slice(4)),
        ...answers[key]
      };
      postAnswers.push(postAnswer)
    }

    console.log(this.state.data, postAnswers)

    if (this.state.questionCount !== postAnswers.length){
      return this.setState({ isError: true, errorMsg: "All fields need to be filled out"})
    } else {
      console.log(this.state.data, postAnswers)
    }
  }

  groupRecords = (carry, next) => {
    let recordGroupLength = 2;
    let counter = 0;

    while(carry[counter]){
      if( carry[counter].length === recordGroupLength ){
        counter++;
      }
      else if( carry[counter].length === 1 ){
        carry[counter].push(next);
        return carry;
      }
    }

    if( carry[counter] === undefined ){
      carry[counter] = [];
      carry[counter].push(next)
      return carry;
    }

    return carry;
}

  renderField = (fieldSet, category) => {
    return fieldSet.map(question => {
      let options = [];
      for( let key in question){
        if (key.includes("answer")){
          options.push({
            key: question[key],
            id: question[key],
            value: key,
            text: question[key]
          })
        }
      }
      return(
        <Form.Field key={`qnum${question.qnum}`}>
          <p>{question.question}</p>
          <Dropdown
            fluid
            selection
            name={`qnum${question.qnum}`}
            section={category}
            onChange={(e, value) => this.handleChange(value)}
            options={options}
          />
        </Form.Field>
      )
    })
  }

  renderSegment = (section) => {
    let groupedFields = section.questions.reduce(this.groupRecords, []);
    return(
      <Segment>
        <Header as="h4">{section.questions[0].section}</Header>
        <Divider style={{marginTop: '1em'}}/>
        {groupedFields.map(fieldSet => (
          <Form.Group widths='equal'>
            {this.renderField(fieldSet, section.category)}
          </Form.Group>
        ))} 
      </Segment>
    )
  }

  renderSectionOne = () => (
    <NewResource 
      {...this.props}
      resource="l3Scores" 
      title="New Value Index Report"
      fieldOverrides={{...fieldOverrides}}
      onSave={this.handleSubmit}
      saveText="Continue"

      groupFields={{
        "Required Information": [
          "companyId",
          "naics",
          "businessType",
          "annualRevenue",
          "b2bb2c",
          "executiveTeamSize",
          "netMargin",
          "version"
        ]
      }}
    />
  )

  renderSectionTwo = () => {
    return(
      <Form autoComplete="off" size="small">
        <Grid>
          <GridColumn width={10}>
            <Header as="h3">New Value Index Report</Header>    
          </GridColumn>
        </Grid>
        
        <Divider style={{ marginTop: '1em', marginBottom: '1em' }} />

        {this.state.sections.map( section => this.renderSegment(section) )}

        <Button primary onClick={this.handleSave}>Save and Submit</Button>
        { this.state.isError ? <p>{this.state.errorMsg}</p> : null }
    </Form>
    )
  }

  render(){
    switch (this.state.activeSection){
      case (3):
        return this.renderSectionThree();
        break;
      case (2):
        return this.renderSectionTwo();
        break;
      case (1): 
        return this.renderSectionOne();
        break;
      default:
        return this.renderSectionOne();
    }
  }
}