import React from 'react';
import View from '../../ui/View';

export default (props) => {
    return (
        <View 
            resource="affiliates" 
            fieldOverrides={{
                "active": value => value === 0 ? "False" : "True",
                "deleted": value => value === 0 ? "False" : "True"
            }}

            groupFields={{
                "Basic Information": [
                    "id",
                    "name",
                    "phone",
                    "description",
                ],

                "Contact Information": [
                    "contact_first_name",
                    "contact_last_name",
                    "contact_phone",
                    "contact_email",
                ],

                "Address Information": [
                    "address",
                    "address_2",
                    "city",
                    "state",
                    "zip",
                ],

                "Plan Information": [
                    "affiliate_type",
                    "affiliate_specialty",
                    "proaffiliate_id",
                    "brain_tree_sub_id"
                ],

                "Extended Information": [
                    "web"
                ],

                "Other": [
                    "active",
                    "deleted"
                ]
            }}
            {...props} 
        />
    )
}