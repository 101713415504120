import React from 'react';
import { Input } from 'semantic-ui-react';
import { moment } from 'moment'; 
import Edit from '../../ui/Edit';
import { dropdown } from '../../ui/Inputs';
import { abbreviated } from '../../data/states';

export default (props) => {
    return (
        <Edit 
            title="Edit" 
            resource="company_users" 

            fieldOverrides={{
                "state": ({name, value, onChange}) => dropdown(name, value, onChange, Object.keys(abbreviated).map(key => ({
                    text: key,
                    value: key
                }))),
                "email": props => <Input {...props} type="email" />,
                "phone": props => <Input {...props} type="number" />,
                "zip": props => <Input {...props} type="number" />,
            }}

            groupFields={{
                "Basic Information": [
                    "first_name",
                    "last_name",
                    "phone",
                    "email",
                ],

                "Address Information": [
                    "address",
                    "city",
                    "state",
                    "zip",
                ],

            }}

            cleanInput={input => {
                input.created_at = moment(input.created_at).format('YYYY-MM-DD');
                input.updated_at = moment(input.updated_at).format('YYYY-MM-DD');
                input.deleted_at = moment(input.updated_at).format('YYYY-MM-DD');

                return input;
            }}

            {...props} 
        />
    )
}