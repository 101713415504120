import React from 'react';
import View from '../../ui/View';

export default (props) => {
    return (
        <View 
            resource="company_users" 

            fieldOverrides={{
                "active": value => value === 0 ? "False" : "True",
                "deleted": value => value === 0 ? "False" : "True"
            }}

            groupFields={{
                "Basic Information": [
                    "id",
                    "first_name",
                    "last_name",
                    "phone",
                    "email",
                ],

                "Address Information": [
                    "address",
                    "city",
                    "state",
                    "zip",
                ],

                "Company Information": [
                    "company_id",
                ],

                "Other": [
                    "active",
                    "deleted"
                ]
            }}
            {...props} 
        />
    )
}