import React, { Component } from 'react';
import nav from '../util/nav';
import { dashboard } from '../routes';
import { Grid, GridColumn, Menu } from 'semantic-ui-react';

export default class Dashboard extends Component{
    nav = nav(dashboard)

    render(){
        return (
            <Grid columns='twelve'>
                <GridColumn width={3}>
                    <Menu vertical inverted pointing compact icon='labeled'>
                        {this.nav.renderNav()}
                    </Menu>
                </GridColumn>
                <GridColumn width={13}>
                    {this.nav.renderRoutes()}
                </GridColumn>
            </Grid>
        )
    }
}