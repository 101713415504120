import React, { Component } from 'react';

import ResourceLookup from '../../../ui/ResourceLookup';
import Selector from '../../../ui/Selector';
import { Input } from 'semantic-ui-react';
import DateLookup from '../../../ui/DateLookup';
import Provisional from './Provisional';
import Multiples from './Multiples';
import ValueIndex from '../valueindex/ValueIndex';

const overrides = {
  "company_id" : props => (
    <ResourceLookup 
      resource="company"
      {...props}
    />
  ),
  "tevo": props => <Input {...props} type="number"/>,
  "assets": props => <Input {...props} type="number"/>,
  "revenue": props => <Input {...props} type="number"/>,
  "naics": props => <Input {...props} type="number"/>,

  "employees": props => <Input {...props} type="number"/>,
  "net_income": props => <Input {...props} type="number"/>,
  "ar": props => <Input {...props} type="number"/>,
  "cash": props => <Input {...props} type="number"/>,
  "ebitda": props => <Input {...props} type="number"/>,
  "multiple_ebitda_upper": props => <Input {...props} type="number"/>,
  "multiple_ebitda_estimate": props => <Input {...props} type="number"/>,
  "multiple_ebitda_lower": props => <Input {...props} type="number"/>,
  "multiple_revenue_upper": props => <Input {...props} type="number"/>,
  "multiple_revenue_estimate": props => <Input {...props} type="number"/>,
  "multiple_revenue_lower": props => <Input {...props} type="number"/>,
  "multiple_assets_upper": props => <Input {...props} type="number"/>,
  "multiple_assets_estimate": props => <Input {...props} type="number"/>,
  "multiple_assets_lower": props => <Input {...props} type="number"/>,
  "multiple_net_income_upper": props => <Input {...props} type="number"/>,
  "multiple_net_income_estimate": props => <Input {...props} type="number"/>,
  "multiple_net_income_lower": props => <Input {...props} type="number"/>,

  "score_date": props => <DateLookup {...props}/>
}

const levelOneFields = {
  "Build Provisional": [
    "company_id",
    "revenue",
    "assets",
    "naics"
  ]
}

const levelTwoFields = {
  "Build Multiples": [
    "company_id",
    "naics",
    "revenue",
    "assets",
    "ebitda",
    "cash",
    "ar",
    "inventory",
    "total_liabilities",
    "employees"
  ]
}

export default props => {
  return ([
    <h1>Build Scores</h1>,
    <Selector options={[
      {
        name: "Provisional",
        render: <Provisional {...props} fields={levelOneFields} overrides={overrides}/>
      },
      {
        name: "Multiples Indicator",
        render: <Multiples {...props} fields={levelTwoFields} overrides={overrides}/>
      },
      {
        name: "Value Index",
        render: <ValueIndex {...props}/>
      }
    ]}/>
  ])
}