import React, { Component } from 'react';
import { getCore, postCore } from '../util/ajax';
import { Form, Header, Divider, Segment, Button, Grid, GridColumn } from 'semantic-ui-react';
import UploadCSV from './UploadCSV';
import DownloadCSV from './DownloadCSV';

export default class Edit extends Component{
    state = {
        fields: {}
    }

    async componentDidMount(){
        this.fetchData();
    }

    fetchData = async () => {
        let getFields = await getCore(`/admin/edit/${this.props.resource}/${this.props.resourceId}`);

        if (getFields.data.adminResource && getFields.data.adminResource.resource) {
            let resource = JSON.parse(getFields.data.adminResource.resource);
        
            this.setState({
                fields: resource
            })
        }
    }

    onChange = ({target}) => {
        let fields = {...this.state.fields};
        fields[target.name] = target.value;

        this.setState({
            fields
        })
    }

    handleSave = async () => {
        if( this.props.onSave ){
            return this.props.onSave(this.state)
        }

        if( this.props.cleanInput ){
            this.state.fields = this.props.cleanInput(this.state.fields)
        }

        try{
            let save = await postCore(`/admin/save/${this.props.resource}/${this.props.resourceId}`, {
                payload: JSON.stringify(this.state.fields)
            });

            if( save.data.adminResourceSave.success && this.props.redirectPath )
                this.props.history.push(this.props.redirectPath)
            else
                this.fetchData()

        } catch(e){
            if (this.props.handleError)
                return this.props.handleError()
            return null
        }
    }

    groupRecords = (carry, next) => {
        if( this.props.hideKeys && this.props.hideKeys.includes(next) ){
            return carry;
        }

        let recordGroupLength = this.props.grouping || 2;
        let counter = 0;

        while(carry[counter]){
            if( carry[counter].length === recordGroupLength ){
                counter++;
            }
            else if( carry[counter].length === 1 ){
                carry[counter].push(next);

                return carry;
            }
        }

        if( carry[counter] === undefined ){
            carry[counter] = [];
            carry[counter].push(next)
            return carry;
        }

        return carry;
    }

    renderFieldset = (fields) => {
        return fields.map(key => {
            const fieldVal = this.state.fields[key];
            const formatted = key.replace(/_/g, " ");
            const hasOverride = this.props.fieldOverrides && this.props.fieldOverrides[key];

            if( hasOverride ){
                let props = {
                    value: fieldVal,
                    name: key,
                    onChange: this.onChange
                }
                return (
                    <Form.Field key={key}>
                        <label style={{textTransform: 'capitalize'}}>{formatted}</label>
                        { this.props.fieldOverrides[key](props) }
                    </Form.Field>                                                
                )
            }
            else{
                return (
                    <Form.Field key={key}>
                        <label style={{textTransform: 'capitalize'}}>{formatted}</label>
                        <input name={key} value={fieldVal} onChange={this.onChange}/>
                    </Form.Field>
                )
            }
        })
    }

    renderFormGroup = (fields) => {
        if( this.props.groupFields && Object.entries(fields).length !== 0 ){
            let targetFields = this.props.groupFields instanceof Function ? this.props.groupFields(this.state.fields) : this.props.groupFields;

            return Object.keys(targetFields).map(cat => {
                let arr = targetFields[cat];
                let groupedFields = arr.reduce(this.groupRecords, []);
                return (
                    <Segment key={cat}>
                        <Header as="h4">{cat}</Header>
                        <Divider style={{marginTop: '1em'}}/>
                        { groupedFields.map(fields => {
                            return (
                                <Form.Group widths='equal'> 
                                    { this.renderFieldset(fields) }
                                </Form.Group>   
                            )
                        })}
                    </Segment>
                )
            })
        }

        return Object.keys(fields).reduce(this.groupRecords, []).map(fieldset => {
            return (
                <Form.Group widths='equal'> 
                    {this.renderFieldset(fieldset)}
                </Form.Group>                
            )
        })
    }

    updateFields = (updatedFields) => this.setState({ fields: updatedFields })
    
    grabFields = () => {
        let targetFields = this.props.groupFields instanceof Function ? this.props.groupFields(this.state.fields) : this.props.groupFields;
        let keys = Object.values(targetFields).flat();
        let entries = Object.entries(this.state.fields).flat();
        let keyValues =[];
        let headerRow=[];
        let valuesRow=[];
        
        entries.forEach((item, index) => {
            if (keys.indexOf(item) > -1) {
                keyValues.push(item)
                keyValues.push(entries[index+1])
            }
        })
        keyValues.forEach(item => {
            if(keyValues.indexOf(item) %2 === 0) {
                headerRow.push(item)
            }
            else {
                valuesRow.push(item)
            }
        })
        return `${encodeURI([headerRow, valuesRow].join("\n"))}`;
    }

    render(){
        return (
            <Form autoComplete="off">
                <Grid>
                    <GridColumn width={10}>
                        <Header as="h3">{this.props.title}</Header>    
                    </GridColumn>
                    <GridColumn width={6}>
                        <Grid>
                            <DownloadCSV grabFields={this.grabFields} {...this.props} {...this.state}/>  
                            <UploadCSV updateFields={this.updateFields} {...this.props}/> 
                        </Grid>
                    </GridColumn>
                </Grid>
                
                <Divider style={{ marginTop: '1em', marginBottom: '1em' }} />
                { this.renderFormGroup(this.state.fields) }  
                <Button primary onClick={this.handleSave}>Save</Button>
            </Form>
        )
    }
}