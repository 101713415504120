import React from 'react';
import { Input } from 'semantic-ui-react';
import { moment } from 'moment';
import Edit from '../../ui/Edit';
import { dropdown } from '../../ui/Inputs';

export default (props) => {
    return (
        <Edit 
            title="Edit" 
            resource="affiliate_users" 

            fieldOverrides={{
                "email" : props => <Input {...props} type="email" />,
                "subscribed_plan_id": ({ name, value, onChange }) => dropdown(name, value, onChange, [
                    { text: 'Trial', value: 'trial' },
                    { text: 'Reseller', value: 'reseller' },
                    { text: 'Gold', value: 'gold' },
                    { text: 'Silver', value: 'silver' },
                    { text: 'Platinum', value: 'platinum' }
                ])
            }}

            groupFields={{
                "Basic Information": [
                    "first_name",
                    "last_name",
                    "work_phone",
                    "mobile",
                    "email",
                ],

                "Extended Information": [
                    "subscribed_plan_id",
                ],
            }}

            cleanInput={input => {
                input.created_at = moment(input.created_at).format('YYYY-MM-DD');
                input.updated_at = moment(input.updated_at).format('YYYY-MM-DD');
                input.deleted_at = moment(input.updated_at).format('YYYY-MM-DD');

                return input;
            }}

            {...props} 
        />
    )
}