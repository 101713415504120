export const defaultFields = {
    "Basic Information": [
        "id",
        "company_id",
        "tevo",
        "level",
        "affiliate_user_id",
        "score_date"
    ]
}

export const levelTwoFields = {
    "Multiples": [
        "multiple_ebitda_upper",
        "multiple_ebitda_estimate",
        "multiple_ebitda_lower",
        "multiple_revenue_upper",
        "multiple_revenue_estimate",
        "multiple_revenue_lower",
        "multiple_net_income_upper",
        "multiple_net_income_estimate",
        "multiple_net_income_lower",
        "multiple_assets_upper",
        "multiple_assets_estimate",
        "multiple_assets_lower",
    ],
    "Input": [
        "revenue",
        "assets",
        "employees",
        "ebitda",
        "naics",
        "ar",
        "cash",
    ],
    "TEVO": [
        "tevo_min",
        "tevo_max",
        "tevo_median",
    ],
    "Percentiles": [
        "percentile_revenue",
        "percentile_ebitda",
        "percentile_cash",
        "percentile_ar",
        "percentile_inventory",
        "percentile_assets",
        "percentile_total_liabilities",
        "percentile_rev_emp"
    ]
}

export const levelOneFields = {
    "Level One": [
        "revenue",
        "assets",
        "naics",
        "ebitda",
    ]
}
