import React from 'react';
import Dashboard from "./dashboard/Dashboard";
import MultiplesIndicators from './dashboard/items/MultiplesIndicators';
import ValueIndex from './dashboard/items/ValueIndex';
import DashboardUsers from './dashboard/items/Users';
import Graphs from './dashboard/items/Graphs';
import AllScores from './dashboard/items/AllScores';
import Companies from './companies/Companies';
import Company from './companies/company/Company';
import Partners from './partners/Partners';
import Partner from './partners/partner/Partner';
import Users from './users/Users';
import User from './users/user/User';
import PartnerUsers from './partner-users/PartnerUsers';
import PartnerUser from './partner-users/user/PartnerUser';
import Scores from './scores/Scores';
import Score from './scores/score/Score';
import Promos from './promos/Promos';
import Promo from './promos/promo/Promo';
import Stats from './stats/Stats';


export const main = [
    {
        text: "Dashboard",
        link: "/dashboard/",
        component: (props) => <Dashboard {...props}/>
    }, 
    {
        text: "Company",
        link: "/companies/company/:id",
        component: (props) => <Company {...props} />,
        noNavItem: true
    },    
    {
        text: "Companies",
        link: "/companies/",
        component: (props) => <Companies />
    },
    {
        text: "Partner",
        link: "/partners/partner/:id",
        component: (props) => <Partner {...props} />,
        noNavItem: true
    },      
    {
        text: "Partners",
        link: "/partners/",
        component: (props) => <Partners />
    },
    {
        text: "Company User",
        link: "/users/user/:id",
        component: (props) => <User {...props} />,
        noNavItem: true
    },  
    {
        text: "Company Users",
        link: "/users/",
        component: (props) => <Users {...props} />
    }, 
    {
        text: "Partner User",
        link: "/partner-users/user/:id",
        component: (props) => <PartnerUser {...props} />,
        noNavItem: true
    },       
    {
        text: "Partner Users",
        link: "/partner-users/",
        component: (props) => <PartnerUsers {...props} />
    },
    {
        text: "Score",
        link: "/scores/score/:id",
        component: (props) => <Score {...props} />,
        noNavItem: true
    },                
    {
        text: "Scores",
        link: "/scores/",
        component: (props) => <Scores {...props} />,
    },   
    {
        text: "Promo",
        link: "/promos/promo/:id",
        component: (props) => <Promo {...props} />,
        noNavItem: true
    },                
    {
        text: "Promo Codes",
        link: "/promos/",
        component: (props) => <Promos {...props} />,
    },            
    {
        text: "Stats",
        link: "/stats/",
        component: (props) => <Stats {...props} />
    }
];


export const dashboard = [
    {
        text: "Home",
        link: "/dashboard/",
        component: (props) => <Graphs {...props}/>,
        exact: true,
    },
    {
        text: "All Scores",
        link: "/dashboard/scores/",
        component: (props) => <AllScores {...props}/>,
        exact: true,
    },
    {
        text: "Multiples Indicator Reports",
        link: "/dashboard/multiples-indicators/",
        component: (props) => <MultiplesIndicators {...props}/>,
        exact: true,
    },
    {
        text: "Value Index Reports",
        link: "/dashboard/value-index/",
        component: (props) => <ValueIndex {...props}/>,
        exact: true,
    },
    {
        text: "Users",
        link: "/dashboard/users",
        component: (props) => <DashboardUsers {...props}/>,
        exact: true,
    }
]