import React from 'react';

import { Header, Divider } from 'semantic-ui-react';
import { ScoresByDateReport } from './reports/ScoresByDate';

export default (props) => {
        return (
            <div>
                <Header as="h2">Multiples Indicator Reports</Header>
                <Divider style={{marginTop: '1em', marginBottom: '2em'}} />                
                <ScoresByDateReport level={2} {...props}/>
            </div>
        )
}