import React from 'react';
import moment from 'moment';

import ViewScore from './score/ViewScore';
import EditScore from './score/EditScore';
import DeleteScore from './score/DeleteScore';
import ScoresBuilder from './score/scorebuilder/ScoresBuilder';
import ScoresManual from './score/ScoresManual';
import { Resource } from '../ui/Resource';
import NewResource from '../ui/NewResource';
import ResourceLookup from '../ui/ResourceLookup';
import Selector from '../ui/Selector';
import FilteredResource from '../ui/FilteredResource';
import { Input } from 'semantic-ui-react';
import DateLookup from '../ui/DateLookup';

const scoresStandardFields = {
    "Basic Information": [
        "company_id",
        "tevo",
        "score_date",
    ],
    "Financial Input": [
        "revenue",
        "assets",
        "employees",
        "net_income",
        "ar",
        "cash",
        "ebitda"
    ]
}

export const scores = [
    {
        text: "Recent",
        link: "/scores/",
        component: (props) => (
            <Resource 
                resource="scores" 
                redirectPath={"/scores/score/"} 
                autoDispatch={true}
                modifyRow={row => {
                    row.manually_created = row.manually_created ? "True" : "False"
                    row.score_date = moment(row.score_date).format("MM/DD/YY")
                    return row
                }}
                showColumns={[
                    "id",
                    "tevo",
                    "company_id",
                    "level",
                    "manually_created",
                    "score_date"
                ]}
                {...props} 
            />),
        exact: true,
    },
    {
        text: "New Manual",
        link: "/scores/new/",
        component: props => <ScoresManual {...props}/>
    },
    {
        text: "New Build",
        link: "/scores/build",
        component: (props) => <ScoresBuilder {...props}/>,
        exact: true,
    }
]

export const score = (scoreId, companyId = 0) => [
    {
        text: "View",
        link: () => `/scores/score/${scoreId}`,
        component: (props) => <ViewScore key="view" {...props} resourceId={scoreId} />,
        exact: true,
    }, 
    {
        text: "Edit",
        link: () => `/scores/score/${scoreId}/edit`,
        component: (props) => <EditScore {...props} resourceId={scoreId} />,
        exact: true,
    },
    {
        text: "Company",
        link: () => `/scores/score/${scoreId}/company`,
        component: props => (
            companyId ? <FilteredResource 
                {...props} 
                title="Company"
                resourceId={companyId} 
                resource="company" 
                redirectTarget="/companies/company/" 
                column="id" 
                showColumns={[
                    "id",
                    "name",
                ]}
            /> : null          
        ),
    },
    {
        text: "Other Scores",
        link: () => `/scores/score/${scoreId}/others`,
        component: props => (
            companyId ? <FilteredResource 
                {...props} 
                title="Scores"
                resourceId={companyId} 
                resource="scores" 
                redirectTarget="/scores/score/" 
                column="company_id" 
                showColumns={[
                    "id",
                    "level",
                    "tevo",
                ]}
            /> : null          
        ),
    },
    {
        text: "Delete",
        link: () => `/scores/score/${scoreId}/delete`,
        component: (props) => <DeleteScore  {...props} resourceId={scoreId} />,
        exact: true,
    },    
]