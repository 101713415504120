import React, { Component } from 'react';
import View from '../../ui/View';
import { defaultFields, levelOneFields, levelTwoFields } from './shared/Fields';

export default class ViewScore extends Component{
    render(){
        return (
            <View 
                resource="scores" 

                fieldOverrides={{}}

                groupFields={fields => {
                    switch(parseInt(fields.level)){
                        case 1:
                            return {
                                ...defaultFields,
                                ...levelOneFields
                            }
                        case 2: 
                            return {
                                ...defaultFields,
                                ...levelTwoFields
                            }
                        default:
                            return {
                                ...defaultFields
                            }
                        
                    }
                }}
                {...this.props} 
            />
        )        
    }
}