import React from 'react';
import { Checkbox, TextArea, Input } from 'semantic-ui-react';

import NewCompanies from './items/NewCompanies';
import EditCompany from './company/EditCompany';
import DeleteCompany from './company/DeleteCompany';
import ViewCompany from './company/ViewCompany';
import RelationshipsCompany from './company/RelationshipsCompany';
import FilteredResource from '../ui/FilteredResource';
import valueIndexMap from '../data/valueIndexMap.json';
import NewResource from '../ui/NewResource';
import { dropdown } from '../ui/Inputs';
import { abbreviated } from '../data/states';

export const companies = [
    {
        text: "Recent",
        link: "/companies/",
        component: (props) => <NewCompanies {...props} />,
        exact: true,
    },
    {
        text: "New",
        link: "/companies/new/",
        component: props => (
            <NewResource 
                {...props}
                resource="company" 
                redirectPath={"/companies/"}
                title="New Company"
                fieldOverrides={{
                    "state": ({name, value, onChange}) => dropdown(name, value, onChange, Object.keys(abbreviated).map(key => ({
                        key: key,
                        text: key,
                        value: key
                    }))),
                    "structure" : ({name, value, onChange}) => dropdown(name, value, onChange, [
                        { text: 'LLC', value: 'llc' },
                        { text: 'SCORP', value: 'scorp' },
                        { text: 'PC', value: 'pc'},
                        { text: 'LP', value: 'lp' }
                    ]),
                    "description": props => <TextArea {...props}/>,
                    "is_private": props => <Checkbox {...props}/>,
                    "contact_email": props => <Input {...props} type="email"/>,
                    "naics": props => <Input {...props} type="number"/>,
                    "phone": props => <Input {...props} type="number"/>,
                    "zip": props => <Input {...props} type="number"/>,
                    "revenue": props => <Input {...props} type="number"/>,
                    "ebitda": props => <Input {...props} type="number"/>
                }}

                groupFields={{
                    "Basic Information": [
                        "name",
                        "description"
                    ],

                    "Contact Information": [
                        "contact_first_name",
                        "contact_last_name",
                        "contact_title",
                        "contact_phone",
                        "contact_email",
                        "phone"
                    ],

                    "Address Information": [
                        "address_1",
                        "address_2",
                        "city",
                        "state",
                        "zip"
                    ],

                    "Financial Information": [
                        "naics",
                        "revenue",
                        "ebitda",
                        "is_private"
                    ],

                    "Extended Information": [
                        "website",
                        "ubi",
                        "linkedin",
                        "structure"
                    ],
                }}
            />
        )
    }    
]

export const company = companyId => [
    {
        text: "View",
        link: () => `/companies/company/${companyId}`,
        component: (props) => <ViewCompany {...props} resourceId={companyId} />,
        exact: true,
    },
    {
        text: "Relationships",
        link: () => `/companies/company/${companyId}/relationships`,
        component: (props) => <RelationshipsCompany {...props} resourceId={companyId} />,
        exact: true,
    },
    {
        text: "Users",
        link: () => `/companies/company/${companyId}/users`,
        component: (props) => <FilteredResource {...props} resourceId={companyId} resource="company_users" redirectTarget="/users/user/" column="company_id" />,
        exact: true
    }, 
    {
        text: "Scores",
        link: () => `/companies/company/${companyId}/scores`,
        component: (props) => <FilteredResource 
            {...props} 
            title="Scores"
            resourceId={companyId} 
            resource="scores" 
            redirectTarget="/scores/score/" 
            column="company_id" 
            showColumns={[
                "id",
                "level",
                "tevo",
            ]}
        />,
        exact: true
    },  
    {
        text: "Value Indexes",
        link: () => `/companies/company/${companyId}/value-indexes`,
        component: (props) => <FilteredResource 
            {...props} 
            resourceId={companyId} 
            resource="l3Scores" 
            redirectTarget="/scores/score/" 
            column="companyId"
            title="Value Indexes"
            showColumns={[
                "id",
                "pending",
                "version",
                "annualRevenue",
                "b2bb2c",
                "executiveTeamSize",
                "netMargin",
            ]}
            modifyRow={(row) => {
                row.pending = row.pending === 0 ? "False" : "True"
                row.netMargin = valueIndexMap.netMargin[row.netMargin]
                row.annualRevenue = valueIndexMap.annualRevenue[row.annualRevenue]
                return row
            }}
            modifiedHeaders = {() => ({
                pending: {text: "Pending"},
                version: {text: "Version"},
                annualRevenue: {text: "Annual Revenue"},
                b2bb2c: {text: "B2B/B2C"},
                executiveTeamSize: {text: "Team"},
                netMargin: {text: "Net Margin"}
            })}
        />,
        exact: true
    },            
    {
        text: "Edit",
        link: () => `/companies/company/${companyId}/edit`,
        component: (props) => <EditCompany {...props} resourceId={companyId} />,
        exact: true,
    },
    {
        text: "Delete",
        link: () => `/companies/company/${companyId}/delete`,
        component: (props) => <DeleteCompany  {...props} resourceId={companyId} />,
        exact: true,
    },    
]